@use "scss/variables.scss" as *;

#join {
    border-left: solid 1px #ccc;
    height: 100%;
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: $clr-light;
    gap: 2vw;

    .instructions {
        display: flex;
        flex-direction: column;
        align-items: left;
        gap: 1vw;
        background: #fafafa;
        padding: 1vw 2vw;
        border-top: solid 1px #ccc;
        background: #fff;
        /* Add position: sticky to keep instructions always visible */
        position: sticky;
        bottom: 0;
        ol {
            li {
                list-style-type: number;
                font-size: 1.1vw;
                margin-bottom: 0.5vw;
                color: $clr-dark;
            }
        }
        .instruction-heading {
            font-size: 1.8vw;
            color: $clr-primary;
        }
        .instruction-step {
        }
    }
}
