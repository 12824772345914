@use "../variables.scss" as *;
@use "scss/mixins/responsivity.scss" as *;
@use "scss/mixins/position.scss" as *;

@mixin flexRow {
    display: flex;
    flex-direction: row;
}

@mixin flexColumn {
    display: flex;
    flex-direction: column;
}
@mixin justifySettings {
    &.justify-start {
        justify-content: flex-start;
    }
    &.justify-end {
        justify-content: flex-end;
    }
    &.justify-between {
        justify-content: space-between;
    }
    &.justify-evenly {
        justify-content: space-evenly;
    }

    &.justify-around {
        justify-content: space-around;
    }
    &.justify-center {
        justify-content: center;
    }
}

@mixin alignSettings() {
    &.align-start {
        align-items: flex-start;
    }
    &.align-center {
        align-items: center;
    }
    &.align-end {
        align-items: flex-end;
    }
}

@mixin link($color: $clr-txt-primary) {
    text-decoration: none;
    font-size: 18px;
    margin: 0;
    color: $color;
    background: transparent;
    border: none;
    &.sm {
        font-size: $fs-100;
    }
    &.md {
        font-size: $fs-200;
    }
    &.lg {
        font-size: $fs-300;
    }
    &:hover {
        color: $clr-primary;
    }
    &.active {
        color: $clr-primary;
    }
    &[disabled] {
        background: #ddd;
    }
}

@mixin btn($bgColor, $color) {
    background: $bgColor;
    color: $color;
    border-radius: $size-250;
    padding: $size-100 $size-200;
    cursor: pointer;
    font-size: $fs-200;
    border: none;
    text-decoration: none;
    &.sm {
        font-size: $fs-100;
    }
    &.md {
        font-size: $fs-200;
    }
    &.lg {
        font-size: $fs-300;
    }
    &:hover {
        box-shadow: 0 1px 10px 2px rgba(#000, 0.2);
    }
    &[disabled] {
        opacity: 0.3;
        background: #ddd;
        cursor: default;
        box-shadow: none;
    }
}

@mixin input() {
    padding: 8px 16px;
    border-radius: 8px;
    border: solid 1px #ccc;
    width: 100%;
    background: #fff;
    &:focus {
        outline: none;
    }
}

@mixin textSizing($fs, $lineHeight: 1, $letterSpacing: normal) {
    font-size: $fs;
    line-height: $lineHeight;
    letter-spacing: $letterSpacing;
    margin: 0;
    max-width: 55ch;
}

@mixin textStyling($weight: 400, $style: normal, $color: $clr-txt-primary, $fontFamily: "Roboto") {
    font-style: $style;
    font-weight: $weight;
    color: $color;
}

.lvt {
    &-btn,
    &-link {
        &-primary {
            @include btn($clr-primary, $clr-white);
        }
        &-secondary {
            @include btn($clr-info, $clr-white);
        }
        &-success {
            @include btn($clr-success, $clr-white);
        }
        &-link {
            @include link();
        }
    }

    &-card {
        @include flexColumn();
        background: #fff;
        border: solid 1px #ccc;
        border-radius: 8px;
        width: 100%;
    }
    &-card-head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        padding: 8px 16px;
        border-bottom: solid 1px #ccc;
    }

    &-card-body {
        padding: 16px;
    }

    &-card-foot {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 8px 16px;
        border-top: solid 1px #ccc;

        @include justifySettings();
        @include alignSettings();
    }

    &-text {
        $headingLetterSpacing: -0.35px;
        margin: 0;
        &-p {
            @include textSizing(1rem);
            @include textStyling(400, normal);
        }
        &-small {
            @include textSizing(0.75rem);
            @include textStyling(400, normal);
        }
        &-b {
            @include textSizing(1rem);
            @include textStyling(500, normal);
        }
        &-label {
            @include textSizing(0.75rem);
            @include textStyling(500, normal);
        }
        &-h1 {
            @include textSizing(2rem, 0.9, $headingLetterSpacing);
            @include textStyling(600, normal);
        }
        &-h2 {
            @include textSizing(1.5rem, 26px, $headingLetterSpacing);
            @include textStyling(500, normal);
        }
        &-h3 {
            @include textSizing(1.25rem, 26px, $headingLetterSpacing);
            @include textStyling(500, normal);
        }
        &-h4 {
            @include textSizing(1.125rem, 26px, $headingLetterSpacing);
            @include textStyling(500, normal);
        }
        &-h5 {
            @include textSizing(1rem, 38px, $headingLetterSpacing);
            @include textStyling(500, normal);
        }
        &-h6 {
            @include textSizing(0.85rem, 38px, $headingLetterSpacing);
            @include textStyling(500, normal);
        }
    }

    &-row {
        @include flexRow();
        width: 100%;
    }

    &-col {
        @include flexColumn();
        width: 100%;
    }
    &-input {
        &-time {
            @include input();
        }
        &-text {
            @include input();
        }
        &-select {
            @include input();
        }
    }
    &-row,
    &-col {
        @include justifySettings();
        @include alignSettings();
    }
}
