.user {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 32px;
    padding: 16px 16px 32px 16px !important;
    .user-heading, .user-text {
        color: var(--text-color);
    }
    .settings {
        flex: 1;
        .setting-row {
            gap: 32px;

            .language {
                width: fit-content;
            }
        }
    }
    .logout-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        gap: 16px;
        background: var(--button-bg);
        color: var(--button-text-color);
    }
}
