@use "scss/variables.scss" as *;
@use "scss/mixins/shapes.scss" as *;

.schedules {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 8px 8px 32px 8px !important;
    .back {
        color: var(--text-color);
        .back-icon {
            margin: 0 16px 0 8px;
            color: var(--button-bg);
        }
    }

    .schedule-name {
        color: var(--text-color);
    }
    .timeslot {
        gap: 24px;
        .timeslot-text {
            color: var(--text-color);
            &.end {
                font-weight: 400;
                font-size: 14px;
            }
        }
        .timeslot-text-multilingual {
            & > * {
                line-height: initial !important;
                font-size: initial !important;
            }
            em {
                font-style: italic !important;
            }
            p {
                * {
                    font-family: initial !important;
                }
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin: 12px 0 !important;
                font-weight: bold !important;
                letter-spacing: 1.01px !important;
            }

            h1 {
                font-size: 20px !important;
                * {
                    font-size: 20px !important;
                }
            }

            h2 {
                font-size: 16px;
                * {
                    font-size: 16px !important;
                }
            }

            h3 {
                font-size: 14px !important;
                * {
                    font-size: 14px !important;
                }
            }

            h4 {
                font-size: 12px !important;
                * {
                    font-size: 12px !important;
                }
            }

            h5 {
                font-size: 10px !important;
                * {
                    font-size: 10px !important;
                }
            }

            h6 {
                font-size: 8px !important;
                * {
                    font-size: 8px !important;
                }
            }

            ul,
            ol,
            pre {
                margin: 8px 0 !important;
            }

            small {
            }

            li {
                padding: 2px 4px !important;
                margin: 0 0 0 24px !important;
            }

            ul {
                li {
                    list-style-type: disc !important;
                }
            }
            ol {
                li {
                    list-style-type: decimal !important;
                }
            }

            a {
                color: var(--highlight-clr) !important;
                font-weight: bold !important;
            }

            strong {
                font-weight: bold !important;
            }

            .ql-align-right {
                text-align: right;
            }
            .ql-align-center {
                text-align: center;
            }
        }
        .timeslot-heading {
            font-size: 1rem;
            line-height: 1;
            letter-spacing: normal;
        }
        .time {
            .start,
            .middle,
            .end {
                text-align: center;
            }
        }
        .info {
            gap: 8px;
        }

        .timeslot-extra {
            gap: 16px;
        }
        .performer {
            gap: 8px;
            .performer-image {
                @include circleCentered(30px);
                border: solid 1px #ccc;
                background-position: center;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
            .performer-info {
                gap: 4px;
                color: var(--text-color);
                & > * {
                    color: var(--text-color);
                }
            }
            .performer-title {
                font-size: 11px;
                color: var(--text-color);
            }
        }
        .show-all-performers-button {
            background: var(--button-bg);
            color: var(--button-text-color);
        }
    }
    .date-heading {
        width: 100%;
        border-bottom: solid 1px #ccc;
    }
    .navigation {
        border-radius: 16px;
        &.multiple {
            box-shadow: 0 0 5px 5px rgba(#000000, 0.2);
        }
        .navigation-dates {
            display: flex;
            overflow-x: auto;
        }
        .navigation-date {
            padding: 8px 16px;
            color: var(--text-color);
            &.active {
                background: var(--button-bg);
                color: var(--button-text-color);
            }
        }
    }
}

.schedules-list {
    gap: 16px;
    display: flex;
    flex-direction: column;
    .schedules-heading {
        color: var(--text-color);
    }
    .schedule-link {
        gap: 8px;
        display: flex;
        align-items: center;
        padding: 16px;
        border: var(--card-border);
        border-radius: var(--card-border-radius);
        background: var(--card-bg);
        color: var(--card-text-color);
        .schedule-icon {
            color: var(--card-icon-color);
        }
    }
}
