@use "scss/variables.scss" as *;

.home {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px 16px 76px 16px !important;
    margin: 0 !important;
    height: fit-content !important;
    overflow-y: auto;
    
    .home-heading {
        display: none; // screen readers need a h1 tag but it would be visually obstructive
    }

    .home-link {
        background: var(--card-bg);
        border: none;
        flex: 0 0 calc(50% - 8px);
        aspect-ratio: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: var(--card-border-radius);
        gap: 8px;
        font-weight: 500;
        letter-spacing: 1.05px;
        color: var(--card-text-color);
        padding: 24px 16px;
        text-decoration: none;
        border: var(--card-border);
        max-width: calc(50% - 8px);
        .info {
            gap: 8px;
        }
        b,h2 {
            font-size: 15px;
            text-align: center;
            word-break: break-word;
            hyphens: auto;
        }
        small {
            font-size: 12px;
            text-align: center;
        }
        .icon {
            flex: 0 0 15%;
            color: var(--card-icon-color);
        }
        &.disabled {
            border: none;
            & > * {
                opacity: 0.2;
            }
        }
        &[hidden] {
            display: none;
        }
    }
}
