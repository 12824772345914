// Colors
$clr-primary: #c4281a;
$clr-secondary: #ee8e88;
$clr-info: #00a3a8;
$clr-success: #8bc400;
$clr-light: #fafafa;
$clr-muted: #ccc;
$clr-dark: #555;
$clr-white: #fff;
$clr-black: #000;
$clr-txt-primary: #555;
$clr-txt-secondary: #000;

$clr-transparent: rgba(#fff, 0);
/* Font sizes*/
/* Replace these with $size ??*/
$fs-100: 10px;
$fs-200: 16px;
$fs-300: 18px;
$fs-400: 20px;
$fs-500: 28px;
$fs-600: 48px;
$fs-700: 54px;
$fs-title: 64px;
$fs-body: 17px;

/* Font weights*/
$weight-100: 100;
$weight-200: 200;
$weight-300: 300;
$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;
$weight-800: 800;
$weight-900: 900;

$fw-light: 200;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: bold;

/* Sizes*/
$size-50: 0.25rem;
$size-100: 0.5rem; // 8px
$size-150: 0.75rem;
$size-200: 1rem; // 16px
$size-250: 1.25rem;
$size-300: 1.5rem; // 24px
$size-350: 1.75rem;
$size-400: 2rem; // 32px
$size-450: 2.25rem;
$size-500: 2.5rem; // 40px
$size-550: 2.75rem;
$size-600: 3rem; // 48px

$menuSize: 60px;

$ff: roboto;