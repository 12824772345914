@use "scss/variables.scss" as *;
.view-chatfeed {
    width: 100%;
    height: 100%;
    background: var(--app-bg);
    overflow: hidden;
    .chatfeed-container {
        height: 100%;
        flex: 0 0 60%;
        .chat {
            height: 100%;
            position: relative;
        }
    }

    .event-image {
        width: 30%;
        max-width: 8vw;
        aspect-ratio: 1;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .heading-row {
        gap: 1vw;
        background: #fff;
    }
    .heading {
        display: flex;
        flex-direction: column;
        gap: 0.5vw;
        .event-name {
            font-size: 1.5vw;
            margin-bottom: 0.5vw;
        }
        .room-name {
            font-size: 1.3vw;
            color: $clr-dark;
            margin-bottom: 0.2vw;
        }
        .room-topic {
            font-size: 0.9vw;
            color: $clr-dark;
        }
    }
}
