@use "../variables.scss" as *;
@use "../mixins/shapes" as *;

#navigation-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    background: var(--app-bg);
    background-size: cover;
    background-position: center;
    .heading,
    .content,
    .nav {
        width: 100%;
    }

    .heading,
    .nav {
        position: absolute;
        left: 0;
    }

    .content {
        background: transparent;
        margin: 0px 0 $menuSize 0;
        padding: 8px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }

    .nav {
        height: $menuSize;

        background-color: var(--nav-bg);
        box-shadow: 0px 0px 5px 2px rgba(#000, 0.15);
        bottom: 0;
        overflow: hidden;
        .nav-item {
            text-decoration: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            gap: 4px;
            padding: $size-50;
            transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
            width: 100%;

            & > * {
                color: var(--nav-button-inactive);
                font-size: $fs-100;
            }
            &.active {
                color: var(--nav-button-active);
                & > * {
                    color: var(--nav-button-active);
                }
            }
            &.disabled {
                opacity: 0.2;
            }
            text-align: center;
        }
    }

    @mixin userColor($color: $clr-muted) {
        color: $color;
        border: solid 1px $color;
    }
    .user-item {
        @include circleCentered();
        @include userColor(var(--nav-button-inactive));
        &.active {
            @include userColor(var(--nav-button-active));
        }
        background: rgba(#ddd, 0.5);
        overflow: hidden;
        margin: 0 5px;
        text-decoration: none;
        font-size: $fs-200;
        font-weight: bold;
        background-size: cover;
    }
}
