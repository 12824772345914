@use "./variables.scss" as *;

@import "@mantine/core/styles.css";

:root {
    --font-family: #{$ff};
    --header-bg-color: #000;
    --header-logo-visibility: visible;
    --header-logo: url("https://storage.googleapis.com/liveto-api/static/img/liveto-logo-white.png");
    --header-text-color: #{$clr-white};

    --app-bg: #fafafa;

    --text-color: #{$clr-txt-primary};

    --card-bg: #fff;
    --card-border: solid 2px #ccc;
    --card-text-color: #{$clr-txt-primary};
    --card-border-radius: 8px;
    --card-icon-color: #{$clr-secondary};

    --nav-button-active: #{$clr-secondary};
    --nav-button-inactive: #ddd;
    --nav-bg: #{$clr-white};

    --button-bg: #{$clr-primary};
    --button-text-color: #{$clr-white};
}
