@use "scss/variables.scss" as *;
@use "scss/mixins/shapes.scss" as *;
$sendInputHeight: 50px;

.chat-password-prompt {
    height: 100%;
    position: relative;
    margin: 0px 0 60px 0 !important;
    display: flex;
    flex-direction: column;
    overflow-y: hidden !important;
    padding: 0 !important;

    .passwordPrompt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        padding: 16px;
        align-items: center;
        height: 100%;
        background: var(--app-bg);
        background-size: cover;

        svg {
            color: var(--text-color);
        }

        h2 {
            font-size: 20px;
            color: var(--text-color);
            text-align: center;
        }

        .password-input {
            border: solid 1px #ccc;
            border-radius: 8px;
            font-size: 24px;
            padding: 16px;
            text-align: center;
        }

        .submit {
            background: var(--button-bg);
            color: var(--button-text-color);
            border: none;
            padding: 16px 32px;
            border-radius: 100vh;
            margin: 16px 0;
            cursor: pointer;
            box-shadow: 0 0 5px 5px rgba(#000, 0.1);
        }
        .back {
            display: flex;
            gap: 16px;
            color: var(--text-color);
            position: absolute;
            top: 16px;
            left: 16px;
        }

        .error {
            background: rgba(red, 0.6);
            width: 100%;
            text-align: center;
            padding: 8px 16px;
            border-radius: 8px;
            color: #fff;
        }
    }
}

.chat {
    height: 100%;
    position: relative;
    margin: 0px 0 60px 0 !important;
    display: flex;
    flex-direction: column;
    overflow-y: hidden !important;

    .passwordPrompt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
        padding: 16px;
        align-items: center;
        height: 100%;
        background: #fff; //var(--app-bg);
        background-size: cover;

        svg {
            color: var(--text-color);
        }

        h2 {
            font-size: 20px;
            color: var(--text-color);
        }

        .password-input {
            border: solid 1px #ccc;
            border-radius: 8px;
            font-size: 24px;
            padding: 16px;
            text-align: center;
        }

        .submit {
            background: var(--button-bg);
            color: var(--button-text-color);
            border: none;
            padding: 8px 16px;
            border-radius: 100vh;
        }
    }

    .chatrooms {
        gap: 8px;
        width: 100%;
        overflow-y: auto;

        .chatlist-heading {
            color: var(--text-color);
        }

        .room-link {
            width: 100%;
            padding: 16px;
            background: var(--card-bg);
            border: var(--card-border);
            border-radius: var(--card-border-radius);

            .room-link-head {
                color: var(--card-text-color);
            }

            .room-link-topic {
                color: var(--card-text-color);
            }
        }
    }

    .load-screen {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(#000, 0.5);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 16px;
        z-index: 2;
        overflow-y: auto;

        .text {
            font-size: 12px;
            font-weight: 500;
            color: var(--text-color);
        }

        &.big {
            .loader {
                width: 3vw;
                height: 1vw;
            }

            .text {
                font-size: 1vw;
            }
        }
    }

    .chat-feed {
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 60px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px 8px 8px 8px;
        overflow-y: auto;

        #bottom {
            padding-top: 60px;
        }

        .fetch-more-messages {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px;
            border: solid 1px $clr-primary;
            color: $clr-primary;
        }

        .no-messages {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 16px;
            gap: 8px;

            & > * {
                color: var(--text-color);
            }
        }

        &.big {
            gap: 1vw;

            --webkit-scrollbar {
                width: 1px;
            }

            .no-messages {
                gap: 1vw;

                .nomessages-heading {
                    font-size: 1vw;
                }

                .nomessages-description {
                    font-size: 0.8vw;
                }
            }
        }
    }

    .restricted-messaging {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: $menuSize;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        background: rgba(#000, 0.8);
        color: #fff;
        font-weight: bold;
    }
    .send-container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        .reply-message-container {
            width: 100%;
            background-color: rgb(235, 235, 235);
            border-left: 5px solid black;
            padding: 0.5rem;
            font-size: 12px;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .message-container {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;
                .sender {
                    font-weight: 700;
                }
                .message {
                    word-break: break-word;
                }
            }
            .unquote-button {
                cursor: pointer;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
        .send {
            height: $menuSize;
            display: flex;
            z-index: 1;
            background: $clr-white;
            border-top: solid 2px $clr-muted;
            border-bottom: solid 2px $clr-muted;

            .input {
                width: calc(100% - 50px);
                height: 100%;
                margin: 0;
                border: none;
                padding: 8px;
                resize: none;
                background: transparent;
                font-size: 13px;
                color: #000;

                &:focus {
                    outline: none;
                }
            }

            .button {
                width: 56px;
                aspect-ratio: 1/1;
                background: #fff;
                color: red;
                border: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0.25rem;

                &:disabled {
                    color: $clr-muted;
                }

                .char-counter {
                    font-size: $fs-100;
                }
            }
        }
    }

    .user-message {
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;
        gap: 4px;

        .message-icon {
            @include circleCentered();
            background: #fff;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border: var(--card-border);
        }

        .message-body {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 100%;
        }

        .sender {
            font-size: 13px;
            font-weight: 700;
            width: 100%;
            color: var(--text-color);
            border-bottom: var(--card-border);
        }

        .messages {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .message {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
            }

            .message-container {
                display: flex;
                flex-direction: column;
                background: var(--card-bg);
                color: var(--card-text-color);
                border-radius: 8px;
                word-break: break-word;
                width: 100%;
                position: relative;

                .quoted-msg-container {
                    font-size: 13px;
                    background-color: #fff;
                    border-left: 5px solid $clr-dark;
                    border-radius: 10px;
                    padding: 0.5rem;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 0.25rem;
                    .sender {
                        font-weight: 700;
                    }
                    .message {
                        word-break: break-word;
                        line-height: 1.3;
                        .quoted-removed {
                            color: $clr-primary;
                        }
                    }
                }

                .message-menu-btn {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    z-index: 1;
                    height: 35px;
                    width: 35px;
                    border-radius: 100vh;
                    border: unset;
                    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
                    background-color: var(--button-bg);
                    color: var(--button-text-color);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &.quoted {
                    padding: 0.5rem;
                    border-radius: 10px;
                    margin-bottom: 8px;
                    background: rgba(150, 150, 150, 0.2);
                    .moderator-title {
                        padding: 0 0 8px 0;
                    }
                    .message-content-message {
                        margin-bottom: 0;
                    }
                    &.reactions {
                        padding-bottom: 0;
                    }
                    .quoted-removed {
                        color: $clr-primary;
                    }
                }

                &.moderator {
                    width: 100%;
                    border: solid 1px $clr-muted;
                    color: $clr-black;
                    line-height: 1.3;

                    .message-content {
                        color: var(--card-text-color);
                        width: 100%;
                    }
                }

                .moderator-title {
                    padding: 8px 8px 0 8px;
                    font-size: 12px;
                    font-weight: bold;
                    color: var(--card-text-color);
                }

                .message-content {
                    width: fit-content;
                    position: relative;
                    font-size: 13px;
                    padding: 8px;
                    line-height: 1.3;
                    width: 100%;
                    overflow: visible;

                    &.held {
                        color: $clr-txt-primary;
                        background-color: $clr-light;

                        .msg-held-info {
                            color: $clr-primary;
                        }
                    }
                }

                .message-reactions {
                    width: 100%;
                    display: flex;
                    gap: 8px;
                    padding: 8px;
                    flex-wrap: wrap;
                }

                .message-reaction {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    background: var(--card-bg);
                    border: var(--card-border);
                    padding: 4px 8px;
                    border-radius: 100vh;

                    .amount {
                        font-size: 12px;
                        color: var(--card-text-color);
                    }
                }
            }
        }

        &.big {
            gap: 0.5vw;

            .message-icon {
                @include circleCentered(5vw);
                font-size: 1.5vw;
            }

            .sender {
                font-size: 1.5vw;
            }

            .messages {
                gap: 0.5vw;
            }

            .message-container {
                padding: 0.5vw;
                border-radius: 0.5vw;

                .moderator-title {
                    font-size: 1.5vw;
                }

                .message-content {
                    font-size: 1.5vw;
                    width: 100%;
                }
            }
        }
    }
}

.chat-heading {
    display: none; // screen readers need a h1 tag but it would be visually obstructive
}

.message-actions-overlay {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 1rem;
    margin-top: 31px;
    margin-bottom: 60px;
    .message-actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.5rem;
        width: 100%;

        .msg {
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 1rem;
            &-content {
                background-color: #fff;
                border-radius: 10px;
                border: 1px solid #bbb;
                padding: 0.5rem;
                width: 100%;
                line-height: 2ch;
                word-break: break-word;
            }
            .close-btn {
                background-color: $clr-primary;
                color: #fff;
                border-radius: 10px;
                border: unset;
                padding: 0.5rem 1rem;
            }
        }
        .action-menu {
            display: flex;
            flex-direction: column;
            width: 70%;
            background: rgba(#fff, 0.8);
            border-radius: 10px;
            &-button {
                background-color: transparent;
                border: unset;
                padding: 1rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:nth-child(odd) {
                    border-bottom: 1px solid #bbb;
                }
            }
        }
        .reaction-menu {
            width: "auto";
            z-index: 1;
            display: flex;
            gap: 8px;
            padding: 4px;
            border-radius: 8px;
            background: rgba(#fff, 0.8);
            justify-content: flex-start;

            .reaction {
                padding: 4px;
                border-radius: 100vh;
                font-size: 18px;

                display: flex;
                align-items: center;
                justify-content: center;
                filter: drop-shadow(1px 1px 1px #000);
            }
        }
    }
}
