@mixin circleCentered($size: 50px) {
    width: $size;
    min-width: $size;
    height: $size;
    min-height: $size;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
