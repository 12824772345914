@use "scss/variables.scss" as *;
@use "scss/mixins/shapes.scss" as *;

.specific-poll {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 !important;
    .poll-notfound {
        color: var(--text-color);
    }

    .poll-content {
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .poll-footer {
        display: flex;
        padding: 1rem;
        justify-content: space-between;
        width: 100%;
        .poll-link {
            &:visited {
                color: var(--text-color);
            }
        }
        .poll-nav-btn {
            border: 2px solid;
            border-color: var(--text-color);
            
            border-radius: 100vw;
            padding: 0.5rem;
            background-color: var(--card-bg);
        }
    }
    .question {
        width: 100%;
        text-align: center;
        background: var(--card-bg);
        padding: 8px 16px;
        color: var(--card-text-color);
        box-shadow: 0 0 5px 5px rgba(#000, 0.1);
        margin: 0;
        border: var(--card-border);
        border-radius: var(--card-border-radius);
    }
    .poll-placeholder {
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: var(--text-color);
    }
    .poll-icon {
        color: var(--card-icon-color);
        opacity: 1;
        transition: opacity 0.6s cubic-bezier(0.79, 0.33, 0.14, 1);
        &.fade {
            opacity: 0;
        }
    }
    .poll-button {
        margin-top: 24px;
        background: var(--button-bg);
        color: var(--button-text-color);
    }
    .answer {
        width: 100%;
        padding: 16px;
        box-shadow: 0 0 5px 5px rgba(#000, 0.1);
        border-radius: 100vh;
        background: var(--button-bg);
        &:disabled {
            background: green;
        }
    }
    .options {
        width: 100%;
        gap: 8px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 16px;
        box-shadow: 0 0 5px 5px rgba(#000, 0.2);
        .options-heading {
            color: var(--card-text-color);
            text-align: center;
            padding-bottom: 16px;
        }
        &.voted {
            box-shadow: none;
            background: transparent;
            border-top: none;
        }

        .go-back {
            margin-top: 16px;
            padding: 8px 16px;
            font-size: 12px;
            width: fit-content;
            background: var(--button-bg);
            color: var(--button-text-color);
        }
    }
    .option {
        padding: 8px 16px;
        border-radius: var(--card-border-radius);
        border: var(--card-border);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        gap: 8px;
        background: var(--card-bg);
        .option-text {
            color: var(--card-text-color);
        }
        .opt-row {
            gap: 16px;
        }
        .dot {
            @include circleCentered(10px);
            border: solid 2px $clr-secondary;
        }
        &.voted {
            box-shadow: 0 0 5px 5px rgba(#000, 0.4);
            border: solid 2px green;
        }
    }

    .results {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: auto 0;
        background: var(--card-bg);
        border-radius: var(--card-border-radius);
        border: var(--card-border);
        width: 100%;
        position: relative;
        .results-heading-row {
            padding: 4px 8px;
            border-bottom: var(--card-border);
        }
        .results-heading {
            color: var(--card-text-color);
        }
        .total-votes {
            color: var(--card-text-color);
        }
        .results-question {
            color: var(--card-text-color);
            text-align: center;
            padding: 8px;
            font-size: 14px;
        }
    }

    .result {
        color: #fff;
        gap: 4px;
        padding: 8px 8px;
        & > * {
            color: var(--card-text-color);
        }
        .row {
            gap: 16px;
            align-items: center;
        }
        .text-percent,
        .text-label {
            color: var(--card-text-color);
        }
        .text-percent {
            width: 50px;
            font-size: 14px;
            text-align: end;
        }
        .text-label {
            width: 100%;
        }

        &.voted {
            background: rgba(green, 0.7);
            .text-percent,
            .text-label {
                color: #fff;
            }
        }
    }
}
