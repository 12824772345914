.scanner {
    background: #000;
    width: 100%;
    height: 100%;

    .heading {
        text-transform: uppercase;
    }
    .heading,
    .instructions {
        padding: 8px 16px;
        color: #fff;
        text-align: center;
    }

    .loading-overlay {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(#000, 0.8);
        z-index: 10;
    }

    .scan {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
