@use "scss/variables.scss" as *;

#authentication {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $clr-white;
    overflow-y: auto;
    max-width: 800px;
    margin: 0 auto;
    .banner {
        width: 100%;
        .event-image {
            width: 100%;
            padding-bottom: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        .language-button {
            position: absolute;
            top: 8px;
            background: transparent;
            border: none;
            color: #fff;
            text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
            font-weight: bold;
            letter-spacing: 2px;
            &.fi {
                right: 88px;
            }

            &.en {
                right: 48px;
            }
            &.se {
                right: 8px;
            }
            &.active {
                color: $clr-secondary;
                text-decoration: underline;
            }
        }
    }

    .guest-input {
        width: 100%;
        border: solid 1px $clr-muted;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: 8px;
    }
    .guest-auth-btn {
        background: $clr-primary;
        color: $clr-white;
        padding: 8px 16px;
        border: solid 1px $clr-primary;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    .logo {
        height: 11px;
    }

    .liveto-heading {
        position: relative;
        margin-top: 32px;
    }
    .auth {
        z-index: 2;
        background: $clr-white;
        padding: 16px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        height: 50vh;

        .event-name {
            color: #000;
            text-align: center;
            width: 100%;
            word-break: break-word;
        }
        .instructions {
            width: 100%;
            text-align: center;
            margin-bottom: 16px;
        }
        .auth-type-text {
            font-size: 12px;
            line-height: 1;
            color: $clr-primary;
            text-transform: uppercase;
            font-weight: 600;
            margin: 0;
            &.liveto {
                position: absolute;
                top: -2px;
                left: 84px;
                font-size: 14px;
            }
        }

        .exit {
            padding: 16px;
        }
    }
}
