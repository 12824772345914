@use "scss/variables.scss" as *;

#view-pollresults {
    height: 100%;
    .poll {
        height: 100%;
        flex: 0 0 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        position: relative;
        background: rgba(#000, 0.2);
        background: #333333; /* fallback for old browsers */
        background: -webkit-linear-gradient(135deg, $clr-info, $clr-dark); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
            135deg,
            $clr-info,
            $clr-dark
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

        .legends {
            width: 100%;
            color: #000;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            padding: 0.5vw 1.5vw;
            width: 100%;
            background: #fff;
            .legend {
                font-size: 0.7vw;
                padding: 0.4vw;
                border-radius: 0.2vw;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                gap: 0.3vw;
                width: 100%;
                &-inner {
                    gap: 0.5vw;
                    .percent {
                        font-weight: bold;
                    }
                }
                &-visualization {
                    width: 100%;
                    height: 1.5vh;
                    background: rgba(#000, 0.3);
                    box-shadow: 0 0 0.1vw 0.1vw rgba($color: #000000, $alpha: 0.5);
                    border-radius: 0.5vw;
                    overflow: hidden;
                    .percentage {
                        height: 100%;
                        width: 100%;
                        transform-origin: left;
                        transition: transform 0.4s ease-in;
                    }
                }
            }
        }
    }
    .heading-row {
        padding: 1vw;
        text-align: center;
        font-size: 2vw;
        align-items: center;
        justify-content: center;
        .question {
            font-size: 2vw;
        }
    }
}
