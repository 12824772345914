@use "scss/variables.scss" as *;

.header {
    height: 32px;
    background: var(--header-bg-color);
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    filter: drop-shadow(0 0 2px rgba($clr-primary, 0.2));
    .title {
        color: var(--header-text-color);
        font-weight: 400;
        font-size: 10px;
        letter-spacing: 1.05px;
        text-transform: uppercase;
    }
    .brand-logo {
        height: 20px;
        visibility: var(--header-logo-visibility);
        content: var(--header-logo)
    }
}
