.event-info {
    padding: 0 !important;
    gap: 16px;
    .image {
        width: 100%;
    }
    .description {
        padding: 8px 16px;
        box-sizing: border-box;

        & > * {
            padding: 8px;
        }
        /* Target all the tags, and force them to use the color defined from the variable. the text uses style tags, and cant target the elements */
        & * {
            color: var(--text-color) !important;
            background-color: transparent !important;
        }
        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 22px;
        }
        h3 {
            font-size: 20px;
        }
        h4 {
            font-size: 18px;
        }
        ul,
        ol {
            padding: 8px 16px;
        }
        .ql-align-center {
            text-align: center;
        }
    }

    .video-container {
        width: 100%;
        padding: 8px;
        .video {
            border: solid 1px #ccc;
            width: 100%;
            aspect-ratio: 1.77;
        }
    }
}
