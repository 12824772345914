.performers {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: auto;
    .performerlist-heading {
        color: var(--text-color);
    }
    .performer-list {
        display: flex;
        flex-direction: column;
        gap: 8px;

    }
    .category {
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        border: var(--card-border);
        border-radius: var(--card-border-radius);
        background: var(--card-bg);
        .category-title,
        .category-tooltip {
            color: var(--card-text-color);
        }
        .category-title {
            text-align: center;
        }
    }
    .performer {
        display: flex;
        flex-direction: row;
        text-decoration: none;
        background: var(--card-bg);
        border-radius: var(--card-border-radius);
        border: var(--card-border);
        overflow: hidden;
        height: auto;
        .performer-name,
        .performer-title,
        .performer-description {
            color: var(--card-text-color);
            text-align: center;
        }

        .performer-info {
            height: fit-content;
            padding: 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
        }
    }
}

.performer-image {
    width: 100px;
    object-fit: contain;
    &.single {
        width: 100%;
        max-width: 240px;
        height: auto;
    }
}

.performer-image-placeholder {
    width: 100px;
    min-width: 110px;
    height: 100px;
    min-height: 90px;
    background: #ddd;
    position: relative;
    border-radius: 10px;
    .icon {
        position: absolute;
        color: #ddd;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.performers {
    padding: 8px 16px !important;
    .back {
        margin: 8px 0 8px 0;

        color: var(--text-color);
        .back-icon {
            color: var(--button-bg);
        }
    }
}

.single-performer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 16px !important;
    .back {
        margin: 8px 0 8px 0;

        color: var(--text-color);
        .back-icon {
            color: var(--button-bg);
        }
    }
    .name {
        font-size: 1.125rem;
        line-height: 26px;
        letter-spacing: -0.35px;
        margin: 0;
        max-width: 55ch;
        font-style: normal;
        font-weight: 500;
    }
    .name,
    .title {
        color: var(--text-color);
    }
    .description {
        color: var(--text-color);
        line-height: 1.4;
        padding: 16px 0;
        max-width: 35ch;

        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 22px;
        }
        h3 {
            font-size: 20px;
        }
        h4 {
            font-size: 18px;
        }
        ul,
        ol {
            padding: 8px 8px 8px 28px;
        }
        .ql-align-center {
            text-align: center;
        }
    }

    .url {
        color: var(--button-bg);
        font-size: 16px;
        font-weight: bold;
        padding: 16px 0;
    }
}
