@use "scss/variables.scss" as *;
@use "scss/mixins/shapes.scss" as *;

.polls {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .polls-heading {
        color: var(--text-color);
    }
    .no-polls-listed {
        color: var(--text-color);
        width: 100%;
        text-align: center;
    }
    .poll {
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 16px;
        background: var(--card-bg);
        color: var(--card-text-color);
        border: var(--card-border);
        border-radius: var(--card-border-radius);
        & > * {
            color: var(--card-text-color);
        }

        &.current {
            box-shadow: 0 0 5px 5px rgba($clr-black, 0.2);
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
        }
        &.upcoming {
            border: solid 1px #ccc;
            gap: 16px;
            align-items: center;
        }
        &.past {
            gap: 8px;
        }
    }
}
