.loading-screen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    p {
        font-size: 14px;
        color: var(--text-color);
    }
}
