.venuemap {
    padding: 8px !important;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .map {
        width: 100%;
        height: auto;
    }

    .map-name {
        color: var(--text-color);
        font-size: 20px;
        margin: 8px 0;
    }

    .map-description {
        color: var(--text-color);
        padding-bottom: 32px;
    }
}
