.maps {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    h1 {
        font-size: 20px;
        padding: 8px;
        color: var(--text-color);
    }
}

.map-link {
    width: 100%;
    padding: 16px;
    background: var(--card-bg);
    border: var(--card-border);
    border-radius: var(--card-border-radius);
    .map-link-head {
        color: var(--card-text-color);
    }
    .map-link-topic {
        color: var(--card-text-color);
    }
}
